<template>
	<section>
        <div class="main">
            <div class="picture">
                <img :src="product.picture" 
                    :title="getTitleName()"
                    :alt="getTitleName()"
                    @click="openFullPicture(product.picture)"
                    @error="helper.setNoImg($event, 'product')" />

                <router-link :to="helper.replaceProductParam(routerPath.TIRE_BRAND, product.brand)" class="brand d-sm-none">
                    <img :src="product.brandLogo" :alt="'ยางรถยนต์ ' + product.brand" :title="'ยางรถยนต์ ' + product.brand" @error="helper.setNoImg($event, 'brand')" />
                </router-link>
			</div>
            <div class="info">
                <div class="favorite-container">
                    <!-- <product-add-favorite :product="product"></product-add-favorite> -->
                </div>
                <router-link :to="helper.replaceProductParam(routerPath.TIRE_BRAND, product.brand)" class="brand d-none d-sm-block">
                    <img :src="product.brandLogo" :alt="'ยางรถยนต์ ' + product.brand" :title="'ยางรถยนต์ ' + product.brand" @error="helper.setNoImg($event, 'brand')" />
                </router-link>
                <h1 v-html="getTitleNameWithLink()"></h1>
                <div class="desc">
                    <div class="data">
                        <div class="item">
                            <label>ขนาดยาง</label>
                            <span>{{ product.size }}</span>
                        </div>
                        <div class="item">
                            <label>ราคาเริ่มต้นเส้นละ</label>
                            <div class="price">
                                <span v-if="product.hasSeller" :class="{ 'grey': !product.priceRange }">{{ product.priceRange ? product.priceRange : 'ไม่มีจำหน่าย' }}</span>
                                <span v-if="!product.hasSeller" class="grey">ไม่มีจำหน่าย</span>
                                <div class="promotion" v-if="product.displayPromotion">
                                    {{ product.displayPromotion }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="operation">
                        <button
                            class="btn btn-warning btn-purchase"
                            :class="{ 'not-allow': !product.hasDelivery }"
                            @click="openStoreList(product.hasDelivery, true)"
                            v-if="product.hasSeller">
                            <b-icon icon="truck" class="icon"></b-icon> ราคาจัดส่ง
                        </button>
                        <button
                            class="btn btn-red-light btn-purchase"
                            :class="{ 'not-allow': !product.hasInstallation }"
                            @click="openStoreList(product.hasInstallation, false)"
                            v-if="product.hasSeller">
                            <b-icon icon="tools" class="icon"></b-icon> ราคาติดตั้ง
                        </button>
                        <a :href="helper.getFacebookLink()"
                            target="_blank"
                            class="btn btn-grey btn-purchase"
                            v-if="!product.hasSeller">
                            <b-icon icon="messenger" class="icon"></b-icon> สอบถามรุ่นอื่นๆ
                        </a>
                    </div>
                    
                    <template v-if="product.country.flag && product.country.text">
                        <div class="certificate" @contextmenu="handler($event)">
                            <div class="info">
                                <label>ประเทศผลิต</label>
                                <div class="data">
                                    <img :src="product.country.flag" :alt="product.country.text" :title="product.country.text" />
                                    <span>{{ product.country.text }}</span>
                                </div>
                            </div>
                            <div class="info" v-if="product.tsi.length > 0">
                                <label>มาตรฐาน มอก. <a :href="routerPath.STATIC_INFO_TSI" target="_blank" title="มาตรฐาน มอก."><b-icon icon="info-circle-fill"></b-icon></a></label>
                                <div class="data">
                                    <img src="@/assets/tsi-logo.jpg" alt="มอก." title="มอก." />
                                    <div>
                                        <button v-for="(item, index) in product.tsi" :key="index" @click="openPDFViewer(item.link)">
                                            {{ item.number }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="info" v-if="product.performance && product.performance.icon">
                                <label>ECO Sticker <a :href="routerPath.STATIC_INFO_EU" target="_blank" title="ECO Sticker"><b-icon icon="info-circle-fill"></b-icon></a></label>
                                <div class="data" v-if="product.performance.icon">
                                    <button @click="openPDFViewer(product.performance.link)" v-if="product.performance.link">
                                        <img :src="product.performance.icon" :alt="'Performance ' + product.name" :title="'Performance ' + product.name" @error="helper.setNoImg($event, 'eco')" />
                                    </button>
                                    <img :src="product.performance.icon" :alt="'Performance ' + product.name" :title="'Performance ' + product.name" v-if="!product.performance.link" @error="helper.setNoImg($event, 'eco')" />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <section class="product-description" :class="{ 'hide': !isToggle }">
            <button class="btn-toggle" @click="isToggle = !isToggle">
                <span><b-icon icon="journal-text"></b-icon> ข้อมูลยางรถยนต์</span>
                <b-icon icon="chevron-down" v-if="!isToggle"></b-icon>
                <b-icon icon="chevron-up" v-if="isToggle"></b-icon>
            </button>

            <button class="btn-expand" @click="isToggle = true" v-if="!isToggle">
                <b-icon icon="chevron-down"></b-icon>
            </button>

            <div class="board">
                <div class="content-product-attribute">
                    <h4>คุณสมบัติยางรถยนต์ <a href="https://shorturl.at/fjyCJ" target="_blank"><b-icon icon="info-circle-fill"></b-icon></a></h4>

                    <div class="icons">
                        <img :src="item.icon" :alt="item.title" :title="item.title" v-for="(item, index) in product.attributes" :key="index" />
                    </div>
                </div>

                <div class="detail">
                    <div class="spec">
                        <div class="box">
                            <section>
                                <h4 class="mb-1">ข้อมูลยาง</h4>
                                <div class="data">
                                    <label>หน้ายางกว้าง</label>
                                    <span>{{ product.tireWidth1 }}</span>
                                    <span>{{ product.tireWidth2 }}</span>
                                </div>
                                <div class="data">
                                    <label>ความสูง ของแก้มยาง (ซีรีย์)</label>
                                    <span>{{ product.tireRatio }}</span>
                                    <span>{{ product.tireRatioUnit }}</span>
                                </div>
                                <div class="data">
                                    <label>เส้นผ่านศูนย์กลาง กระทะล้อ</label>
                                    <span>{{ product.tireDiameter }}</span>
                                    <span>{{ product.tireDiameterUnit }}</span>
                                </div>
                                <div class="data">
                                    <label>แก้มยางสูง</label>
                                    <span>{{ product.sidewall1 }}</span>
                                    <span>{{ product.sidewall2 }}</span>
                                </div>
                                <div class="data">
                                    <label>ความสูงของยางโดยรวม</label>
                                    <span>{{ product.tireHeight1 }}</span>
                                    <span>{{ product.tireHeight2 }}</span>
                                </div>
                                <div class="data">
                                    <label>ความเร็วสูงสุด</label>
                                    <span>รหัส {{ product.maxSpeedUnit }}</span>
                                    <span>{{ product.maxSpeedKm }}</span>
                                </div>
                                <div class="data">
                                    <label>รับน้ำหนักต่อล้อ</label>
                                    <span>รหัส {{ product.weightTireIndex }}</span>
                                    <span>{{ product.weightTireKg }}</span>
                                </div>
                            </section>
                        </div>

                        <div class="info-graphic" v-if="product.specs.length > 0">
                            <div class="wrapper" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
                                <img :src="item"
                                    :alt="getTitleName()"
                                    :title="getTitleName()"
                                    @click="openFullPicture(item)"
                                    v-for="(item, index) in product.specs" :key="index" />
                            </div>
                        </div>
                    </div>

                    <div class="price-list">
                        <h4>ราคาเปรียบเทียบจากร้านยางชั้นนำ</h4>

                        <div class="wrapper">
                            <div class="item" v-for="(item, index) in product.priceList" :key="index">
                                <template v-if="item.price !== 'N/A' && item.link">
                                    <router-link :to="item.link" :title="item.title">
                                        <img :src="item.logo ? item.logo : require('@/assets/default-price-logo.png')" :alt="item.title" :title="item.title" />
                                        <span>{{ item.price }}</span>
                                    </router-link>
                                </template>
                                <template v-else>
                                    <img :src="item.logo ? item.logo : require('@/assets/default-price-logo.png')" :alt="item.title" :title="item.title" />
                                    <span>{{ item.price }}</span>
                                </template>
                            </div>
                        </div>
                    </div>

                    <article id="articleContent" class="content-article" v-html="product.article"></article>

                    <div class="operation d-none d-sm-block text-center">
                        <button
                            class="btn btn-warning btn-purchase"
                            :class="{ 'not-allow': !product.hasDelivery }"
                            @click="openStoreList(product.hasDelivery, true)"
                            v-if="product.hasSeller">
                            <b-icon icon="truck" class="icon"></b-icon> ดูราคาจัดส่ง
                        </button>
                        <button
                            class="btn btn-red-light btn-purchase"
                            :class="{ 'not-allow': !product.hasInstallation }"
                            @click="openStoreList(product.hasInstallation, false)"
                            v-if="product.hasSeller">
                            <b-icon icon="tools" class="icon"></b-icon> ราคาติดตั้ง
                        </button>
                        <a :href="helper.getFacebookLink()"
                            target="_blank"
                            class="btn btn-grey btn-purchase"
                            @click="askForInfo()"
                            v-if="!product.hasSeller">
                            <b-icon icon="messenger" class="icon"></b-icon> สอบถามรุ่นอื่นๆ
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <div class="content-related-items mt-5" v-if="productRelate.length > 0 && isProductRelateReady">
            <div class="loading" v-if="!isProductRelateReady">
                <div class="item">
                    <loader-content :isDisplay="true"></loader-content>
                </div>
            </div>

            <template v-if="isProductRelateReady">
                <div class="bar mb-2">
                    <h2><b-icon icon="box-seam" class="icon"></b-icon> ยางรถยนต์รุ่นอื่นๆ</h2>
                </div>
                <product-related :products="productRelate" column="column-5" class="d-none d-lg-block"></product-related>
                <product-related :products="productRelate" column="column-3" class="d-none d-md-block d-lg-none"></product-related>
                <product-related :products="productRelate" column="column-2" class="d-sm-block d-md-none"></product-related>     
            </template>
		</div>

        <div class="content-related-items mt-2">
            <div class="loading" v-if="!isContentRelateReady">
                <div class="item">
                    <loader-content :isDisplay="true"></loader-content>
                </div>
            </div>

            <template v-if="isContentRelateReady">
                <blog-relate
                    :relatePromotions="relatePromotions"
                    :relateArticlesLatest="relateArticlesLatest"
                    :relateArticlesByCategory="relateArticlesByCategory">
                </blog-relate>
            </template>
        </div>

        <modal-full-picture
            :pictureList="fullPicture"
            :isDisplay="isShowFullPicture"
            @close="closeFullPicture()">
        </modal-full-picture>

        <modal-store-list
            @close="closeStoreList()"
            :isDisplay="isOpenStoreList"
            :isDelivery="isDelivery"
            :product="product"
            trackingReferrer="productDetail"
            :networkId="networkId">
        </modal-store-list>

        <pdf-viewer :file="selectedPDF" v-if="selectedPDF" @close="closePDFViewer()"></pdf-viewer>
	</section>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import ProductService from '@/services/productService';
import OrderService from '@/services/orderService';
import GTMService from '@/services/gtmService';
import MixinArticleContent from '@/mixins/articleContent';
import MixinDragScroll from '@/mixins/dragScroll';
import PdfViewer from '@/components/gadget/PdfViewer';
// import ProductAddFavorite from '@/components/product/AddFavorite';
import ModalStoreList from '@/components/store/ModalStoreList';
import ProductRelated from '@/components/product/ProductRelated';
import BlogRelate from '@/components/blog/BlogRelate';

export default {
    mixins: [ MixinArticleContent, MixinDragScroll ],
    components: {
        PdfViewer,
        // ProductAddFavorite,
        ModalStoreList,
        ProductRelated,
        BlogRelate
    },
    data() {
        return {
            helper: Helper,
            routerPath: RouterPath,
            isShowFullPicture: false,
            isOpenStoreList: false,
            isDelivery: false,
            isToggle: false,
            fullPicture: [],
            selectedPDF: null,
            isProductRelateReady: false,
            isContentRelateReady: false,
            productRelate: [],
            relatePromotions: [],
			relateArticlesLatest: [],
			relateArticlesByCategory: []
        };
    },
	props: {
        product: Object,
        networkId: {
            type: String,
            required: false
        }
    },
    mounted() {
        GTMService.gtmViewProduct(this.$gtm, this.product);
        this.getRelatedProduct();
        this.getRelatedContent();
    },
    methods: {
        getTitleName() {
            return [ 'ยางรถยนต์', this.product.brand, 'รุ่น', this.product.name, this.product.size ].join(' ');
        },
        getTitleNameWithLink() {
            const brandLink = '<a href="' + Helper.replaceProductParam(RouterPath.TIRE_BRAND, this.product.brand) + '">' + this.product.brand + '</a>';
            const modelLink = '<a href="' + Helper.replaceProductParam(RouterPath.TIRE_BRAND, this.product.brand + '?model=' + encodeURIComponent(this.product.name)) + '">' + this.product.name + '</a>';
            const sizeLink = '<a href="/' + RouterPath.TIRE + '/?tw=' + this.product.tireWidth + '&tr=' + this.product.tireRatio + '&td=' + this.product.tireDiameter + '">' + this.product.size + '</a>';

            return [ 'ยางรถยนต์', brandLink, 'รุ่น', modelLink, sizeLink ].join(' ');
        },
        openFullPicture(image) {
            this.fullPicture = [];
            this.fullPicture.push(image);

            this.isShowFullPicture = true;
        },
        closeFullPicture() {
            this.isShowFullPicture = false;
        },
        async openPDFViewer(path) {
            const array = await this.getPDFBase64(path);

            this.selectedPDF = array;
        },
        closePDFViewer() {
            this.selectedPDF = null;
        },
        openStoreList(isAllowAction, isDelivery) {
            if (isAllowAction) {
                this.isDelivery = isDelivery;
                this.isOpenStoreList = true;

                // Tracking
                if (isDelivery) {
                    OrderService.trackingDeliveryButton(this.product.id);
                }

                this.$root.$emit('rootToggleFacebookChat', false);
            }
        },
        closeStoreList() {
            this.isOpenStoreList = false;
        },
        async getPDFBase64(path) {
            let loader = this.$modalLoader.render();
            const param = { path: path }
            const result = await ProductService.getPDFBase64(param);
            
            loader.hide();

            if (result.data && result.data.file) {
                return this.base64ToArrayBuffer(result.data.file);
            } else {
                this.$swal(Helper.warningAlert('ไม่พบไฟล์เอกสาร', 'ดูเหมือนว่าไฟล์เอกสารไม่สามารถดึงมาอ่านได้ชั่วคราว ในกรณีที่ต้องการอ่านข้อมูลในเอกสารโปรดติดต่อทีมงานของเรา'));

                return null;
            }
        },
        base64ToArrayBuffer(base64) {
            const binary = window.atob(base64);
            const length = binary.length;
            const bytes = new Uint8Array(length);

            for (var i = 0; i < length; i++) {
                bytes[i] = binary.charCodeAt(i);
            }

            return bytes.buffer;
        },
        handler(e) {
            e.preventDefault();
        },
        async getRelatedProduct() {
            this.isProductRelateReady = false;

            const result = await ProductService.getRelatedProduct(this.product.id, '', '');

			if (result?.data) {
                this.productRelate = result.data.resultList;
			}

            this.isProductRelateReady = true;
        },
        async getRelatedContent() {
            this.isContentRelateReady = false;

            const result = await ProductService.getRelatedContent(this.product.id);

			if (result?.data) {
				this.relatePromotions = result.data.promotions;
				this.relateArticlesLatest = result.data.articlesLatest;
				this.relateArticlesByCategory = result.data.articlesByCategory;
			}

            this.isContentRelateReady = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';
@import '@/components/product/style/ProductDetail.scss';

.main {
    display: flex;
    background: #fff;
    padding: 20px;
    margin-top: 30px;
    @include borderRadius(7px);
    @include boxShadow(0 0 10px rgba(0, 0, 0, 0.2));

    @media only screen and (max-width: $screenExtraLarge) {
        margin-top: 10px;
    }

    @media only screen and (max-width: $screenExtraSmall) {
        display: block;
        padding: 10px;
    }

    .picture {
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        width: 290px;
        margin-right: 15px;
        border: 1px solid #ddd;
        overflow: hidden;
        @include borderRadius(7px);

        @media only screen and (max-width: $screenSmall) {
            width: 36%;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            width: 100%;
            padding-top: 50%;
            margin: 0 auto 15px;
        }

        img {
            width: 100%;
            display: block;
            cursor: pointer;

            @media only screen and (max-width: $screenExtraSmall) {
                position: absolute;
                top: -10%;
                left: 0;
            }
        }

        .brand {
            position: absolute;
            width: auto;
            top: 10px;
            left: 10px;
        }
    }

    .brand {
        height: 35px;

        img {
            height: 35px;
            border: 1px solid #ccc;
        }
    }

    .info {
        position: relative;
        flex: 1;

        .favorite-container {
            position: absolute;
            top: 0;
            right: 0;
        }

        h1 {
            margin: 10px 0;
            font-family: 'Open Sans', 'Kanit';
            font-weight: bold;
            font-size: 22px;
            color: #333;

            @media only screen and (max-width: $screenMedium) {
                font-size: 18px;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                font-size: 16px;
                padding-right: 30px;
            }

            ::v-deep a {
                color: #333;
                text-decoration: underline;
            }
        }

        .desc {
            border-top: 1px dotted #ccc;
            margin-top: 15px;
            padding-top: 20px;

            @media only screen and (max-width: $screenExtraSmall) {
                padding-top: 0;
                margin-top: 10px;
            }

            .data {
                display: flex;

                @media only screen and (max-width: $screenMedium) {
                    display: block;
                }

                @media only screen and (max-width: $screenExtraSmall) {
                    display: flex;
                    justify-content: space-between;
                }

                .item {
                    margin-right: 50px;

                    @media only screen and (max-width: $screenMedium) {
                        margin: 10px 0 0 0;
                    }

                    label {
                        display: block;
                        font-size: 13px;
                        margin: 0;
                        color: #777;
                    }

                    span {
                        display: block;
                        font-size: 26px;
                        line-height: 26px;
                        font-weight: 700;
                        color: #9b8f83;

                        @media only screen and (max-width: $screenMedium) {
                            font-size: 18px;
                            line-height: 20px;
                        }

                        @media only screen and (max-width: $screenExtraSmall) {
                            font-size: 16px;
                        }
                    }

                    .price {
                        display: flex;
                        align-items: center;

                        span {
                            color: #bf3c3c;
                            font-family: Verdana;
                            font-weight: bold;

                            &.grey {
                                color: #999;
                            }
                        }

                        .promotion {
                            display: block;
                            padding: 2px 5px;
                            font-size: 11px;
                            background: #fbe5d6;
                            color: #cc0000;
                            margin-left: 5px;
                        }
                    }
                }
            }

            .certificate {
                max-width: 400px;

                @media only screen and (max-width: $screenExtraSmall) {
                    margin-top: 20px;
                    padding-top: 10px;
                    border-top: 1px solid #333;
                    max-width: unset;
                }

                .info {
                    display: flex;
                    align-items: center;
                    padding: 7px 0;
                    border-bottom: 1px dashed #ddd;

                    &:last-child {
                        border-bottom: none;
                    }

                    label {
                        color: #333;
                        margin: 0;
                        font-size: 13px;
                        width: 160px;
                        display: flex;
                        align-items: flex-start;

                        @media only screen and (max-width: $screenSmall) {
                            width: 120px;
                        }

                        @media only screen and (max-width: $screenExtraSmall) {
                            width: 140px;
                        }

                        a {
                            margin-left: 3px;
                            font-size: 12px;
                            color: #555;
                        }
                    }

                    .data-list {
                        font-size: 13px;

                        span {
                            display: block;
                        }
                    }

                    .data {
                        display: flex;
                        align-items: center;

                        img {
                            display: block;
                            height: 35px;
                            margin-right: 5px;

                            @media only screen and (max-width: $screenExtraSmall) {
                                height: 30px;
                            }
                        }

                        a, button, span {
                            font-size: 12px;
                            line-height: 14px;
                            color: #333;
                            display: block;
                            background: none;
                            border: none;
                            outline: none;
                        }
                    }
                }
            }
        }
    }
}

.product-description {
    position: relative;
    margin-top: 30px;

    .board {
        background: #fff;
        border-top: 2px solid #ffd800;
        padding: 20px;
        margin-top: 10px;
    }

    &.hide {
        .board {
            -webkit-mask-image: linear-gradient(to bottom, black 30%, transparent 100%);
            mask-image: linear-gradient(to bottom, black 30%, transparent 100%);
            height: 250px;
            overflow: hidden;
        }
    }

    .btn-toggle {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border: none;
        outline: none;
        background: none;
        font-weight: bold;
        font-size: 18px;
        color: #333;
        padding: 0;
        cursor: pointer;

        span {
            font-family: 'Open Sans', 'Kanit';
        }

        &:hover {
            opacity: 0.75;
        }
    }

    .btn-expand {
        position: absolute;
        left: 50%;
        z-index: 1;
        margin-left: -17px;
        display: block;
        bottom: 0px;
        background: rgba(50, 50, 50, 0.75);
        border: none;
        color: #fff;
        font-size: 25px;
        padding: 0 5px;
        cursor: pointer;
        @include borderRadius(5px);
        @include boxShadow(0 0 10px rgba(100, 100, 100, 0.5));
    }
}

.operation {
    margin: 20px 0;
    background: #fff;

    @media only screen and (max-width: $screenExtraSmall) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        z-index: 2;
        padding: 5px;
        display: flex;
        @include boxShadow(0 0 15px rgba(100, 100, 100, 1));
    }

    .btn-purchase {
        font-size: 18px;
        font-weight: bold;
        padding: 10px 16px;
        margin-right: 12px;

        &.not-allow {
            cursor: not-allowed;
            background: #ddd;
            border: 1px solid #ddd;
            color: #aaa;

            &:hover {
                opacity: 1;
            }
        }
        
        .icon {
            margin-right: 10px;
        }

        @media only screen and (max-width: $screenMedium) {
            padding: 8px 10px;
            margin-right: 5px;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            flex-basis: 100%;
            padding: 16px 0;
            @include borderRadius(10px);

            &:nth-child(2):first-child {
                flex-basis: 50%;
            }
        }

        & + .btn-purchase {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
</style>